import './App.css';
import JungleHome from './components/Jungle-home/JungleHome.js';

function App() {
  return (
    <div className="App">
     <JungleHome/>
    </div>
  );
}

export default App;
